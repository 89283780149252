import styled from '@emotion/styled'
import { Props as ImageProps } from 'app/components/Common/Image'
import { Image } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import Fade from 'react-reveal/Fade'

import { Benefit, Props as BenefitProps } from './Benefit'

export interface Props {
  backgroundImage?: ImageProps
  benefits?: BenefitProps[]
  title?: string
}

export const VisualBenefits = memo(function VisualBenefits({
  backgroundImage,
  benefits,
  title,
}: Props) {
  if (!benefits) {
    return null
  }

  return (
    <Container>
      {backgroundImage ? (
        <Background>
          <Image {...backgroundImage} />
        </Background>
      ) : null}
      <Wrapper row wrap>
        <LeftSide>
          {title ? (
            <Fade left distance="7.5rem">
              <Title>{title}</Title>
            </Fade>
          ) : null}
        </LeftSide>
        <RightSide>
          {benefits
            ? benefits.map((item, index) => (
                <Fade bottom distance="3.75rem" key={index}>
                  <Benefit {...item} />
                </Fade>
              ))
            : null}
        </RightSide>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  padding: 5rem 10vw;
  position: relative;

  @media (max-width: 1199px) {
    margin-bottom: 6rem;
    position: relative;
    padding: 3.75rem 8.454vw;
    &:after {
      content: '';
      width: 100%;
      height: 6rem;
      background: ${({ theme }) => theme.colors.variants.neutralLight3};
      position: absolute;
      bottom: -6rem;
      left: 0;
    }
  }

  @media (max-width: 1023px) {
    margin-bottom: 0;
    &:after {
      display: none;
    }
  }
`

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.8;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const Wrapper = styled(FlexBox)`
  position: relative;
  z-index: 2;
`

const LeftSide = styled.div`
  width: 50%;
  padding-right: 3.75rem;

  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 3rem;
    padding-right: 0;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  font-weight: 200;
  letter-spacing: 0.3125rem;
  line-height: 3.75rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 2.1875rem;
    letter-spacing: 0.1875rem;
    line-height: 2.625rem;
  }
`

const RightSide = styled.div`
  width: 50%;
  > div {
    margin-top: 1.125rem;
    position: relative;
    z-index: 2;
    &:first-of-type {
      margin-top: 0;
    }
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`
